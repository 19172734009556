// 首页Home
// 引入defineStore
import { defineStore } from "pinia";

// 导出
export const homeData = defineStore("homeData", {
    state() {
        return {
            text: null,
            textb: null,
            bulidRole:'22222',
            refreshHome:false,
            balance:false,
            ScoreData:[],
            // 知识产权选择了那个
            isLoage:0
        }
    },
    actions: {
        UP_TEXT(payload) {
            this.text = payload
        },


        setBulidRole(state) {
            this.bulidRole = state
          
        },

        setRefreshHome(state) {
            this.refreshHome = state
        },

        setBalance(type) {
            this.balance = type;
        },

        setScoreData(table) {
            this.ScoreData = [...table]
        },
        setISLOAGE(type) {
            this.isLoage = type
        }
    }
});