// 得到两位小数，且不会四舍五入
export function toFixedFun (num)  {
    const arr = num.toString().split('.');
    if (arr.length === 1) {
      return num + '.00';
    } else if (arr[1].length > 2) {
      return `${arr[0]}.${arr[1].substr(0, 2)}`;
    } else {
      return `${arr[0]}.${arr[1]}`;
    }
  };


// 万元格式化
export function comdify(num) {
 
      var reg = /\d{1,3}(?=(\d{3})+$)/g
 
      return (num + '').replace(reg, '$&,')
 
    }



