<template>
  <div class="app">
    <!-- <router-view v-slot="{ Component }">
      <keep-alive>
        <component :is="Component" :key="$route.name" v-if="$route.meta.keepAlive" />
      </keep-alive>
      <component :is="Component" :key="$route.name" v-if="!$route.meta.keepAlive" />
    </router-view> -->
    <router-view></router-view>
  </div>
</template>

<script>
import { ElMessage } from "element-plus";
import { defineComponent,onMounted } from "vue";

// import screenfull from "screenfull";
export default defineComponent({
  setup() {
    // 定义一个 debounce 函数，用于延迟执行回调函数
    const debounce = (callback, delay) => {
      let tid; // 定义一个计时器变量
      return function (...args) {
        const ctx = self; // 获取当前上下文
        tid && clearTimeout(tid); // 如果计时器存在，则清除它
        tid = setTimeout(() => {
          callback.apply(ctx, args); // 延迟一定时间后执行回调函数
        }, delay);
      };
    };

    const _ = window.ResizeObserver; // 获取全局对象中的 ResizeObserver
    window.ResizeObserver = class ResizeObserver extends _ {
      constructor(callback) {
        callback = debounce(callback, 20); // 使用 debounce 函数包装回调函数
        super(callback); // 调用父类 ResizeObserver 的构造函数
      }
    };

    

   
  // if (!screenfull.isEnabled) {
  //   //浏览器是否允许全屏
  //   ElMessage("错误");
  //   return false;
  // }

  // screenfull.toggle(); //在全屏和非全屏之间切换

  },
});

// 在App.vue中
// import {ref} from 'vue';
// import { provide,nextTick } from 'vue'
// const isRouterActive = ref(true)
// provide('reload', () => {
//   isRouterActive.value = false
//   nextTick(() => {
//     isRouterActive.value = true
//   })
// })
// console.clear()
</script>

<style>

* {
  margin: 0;
  padding: 0;
  user-select: none;
  box-sizing: border-box;
}

@import url("@/assets/publicCSS/myBtn.css");

/* 背景颜色 */
.home {
  /* width: 1920px;
  height: 1080px;
  background-color: black;
  text-align: center;
  position: relative; */
}

.home{
  width: 100vw;
  height: 1080px;
  min-height: 100vh;
  background: url(./assets/img/CBP/底背景.png) no-repeat 100% 100%;
  min-width: 1200px;
  position: relative;
  text-align: center;
}

/* 按钮展开收起 */
.bts-2 {
  position: absolute;
  top: 756px;
  right: 100px;
  cursor: pointer;
}

.bts-2 > p {
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #ffffff;
}

.bts-2 > img {
  width: 30px;
}

.trabts1 {
  right: 18px;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
}

.small-size {
  font-size: 14px;
  -webkit-transform-origin-x: 0;
  /*缩小后文字居左*/
  -webkit-transform: scale(0.8);
  /*关键*/
  transform: scale(0.8);
}

/* 二级页面中间背景 */
.content {
  width: 1889px;
  height: 884px;
  background-image: url(./assets/img/apply/背景.png);
  background-repeat: no-repeat;
  background-size: 100%;
  position: absolute;
  top: 172px;
  left: 15px;
}

/*定义整体的宽度*/
::-webkit-scrollbar {
  width: 5px;
}

/*定义滚动条轨道*/
::-webkit-scrollbar-track {
  border-radius: 5px;
}

/*定义滑块*/
::-webkit-scrollbar-thumb {
  border-radius: 5px;
  background: #ccc;
}

/* 弹窗背景 */
.wrap {
  width: 914px;
  height: 614px;
  background-image: url(./assets/img/apply/弹窗.png);
  background-repeat: no-repeat;
  background-size: 100%;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  animation: wrap 0.5s;
}

/* 弹窗动画 */

@keyframes wrap {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

/* 关闭效果 */
.wrap > img:hover {
  opacity: 0.7;
}

/* 避免花屏 */
[v-cloak] {
  display: none;
}

.peoperLeft {
  width: 360px;
  position: absolute;
  top: 100px;
  left: 50px;
}
.peoperLeft > span {
  color: #00a8ff;
  font-size: 20px;
  width: 20px;
  position: absolute;
  bottom: 0px;
  left: 200px;
}
.peoperLeft > img {
  width: 230px;
  margin: 60px 0 0 200px;
}
option {
  background-color: #1a466d !important;
}



</style>
