// 引入defineStore
import { defineStore } from "pinia";

// 导出
export const control = defineStore("control", {
    // persist: {
    //     enabled: true,//pinia数据持久化
    //     strategies: [
    //         {
    //             key: "perscontrol",
    //             storage: localStorage,
    //         }
    //     ]
    // },
    state() {
        return {
            text: true,
            handleRole: [],
            registeringRoles: false,
           
        }
    },
    actions: {
        UP_SWITCHING(payload) {
            this.text = payload;
        },
        // 存储角色控制布尔值
        UP_HANDLE(payload) {
            this.handleRole = payload;
            console.log(this.handleRole);
        },
        SET_LOGINYES(payload) {
            this.registeringRoles = payload;
        }

    }
});