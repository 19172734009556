// 引入defineStore
import { defineStore } from "pinia";

// 导出  首页课程
export const courseHome = defineStore("userCourseHome", {
    state() {
        return {
            textCourse: '',
        }
    },
    getters: {
        //取
        // GET_COURSE() {
        //     this.getCompany()
        // },
    },
    actions: {

        setTextCourse(val) {
            this.textCourse = val;
        }
        

    }
});