import { createRouter, createWebHashHistory, createWebHistory } from "vue-router";

import Home from '../view/student/Home'
import { ElMessage } from 'element-plus'

const route = createRouter({
    history: createWebHashHistory(),
    routes: [
        {
            path: '/login',
            name: 'login',
            component: () => import('../view/login/Login')
        },
        {
            path: '/regist',
            name: 'regist',
            component: () => import('../view/login/Register')
        },

        {
            path: '/scene',
            name: 'scene',
            component: () => import('../view/student/Scene')
        },
        {
            path: '/scene',
            name: 'scene',
            component: () => import('../view/student/Scene')
        },
        
        {
            // 主页
            path: '/',
            name: 'home',
            component: Home,
            children: [
                // 个人中心
                {
                    path: '/userinfo',
                    name: 'userinfo',
                    component: () => import('../view/student/UserInfo')
                },
                // 历史记录
                {
                    path: '/history',
                    name: 'history',
                    component: () => import('../view/student/History')
                },
                // 资金余额
                {
                    path:'/Balance',
                    component: () => import('../components/student/Balance/Balance.vue'),
                },
                // 代工采购
                {
                    path: '/Oem1',
                    component: () => import('../components/student/Personnel/Procurement.vue/Oem1.vue'),
                },
                
                {
                    // 市场筹划弹窗
                    path: '/dtt1',
                    component: () => import('../components/student/Personnel/DirectorSales/dtt1.vue')
                },
                // 研发中心弹窗
                // {
                //     path: '/development',
                //     component: () => import('../components/student/Development/development.vue')
                // },
                {
                    path: '/dev2',
                    component: () => import('../components/student/Development/dev2.vue')
                },


                // {
                //     path: 'dt2',
                //     component: () => import('../components/student/Personnel/DirectorSales/dt2')
                // },
                // 市场动态
                {
                    path: '/dt1',
                    component: () => import('../components/student/Personnel/DirectorSales/dt1.vue')
                },
                {
                    path: '/dt13',
                    component: () => import('../components/student/Personnel/DirectorSales/dt13.vue')
                },
                {
                    path: '/dt17',
                    component: () => import('../components/student/Personnel/DirectorSales/dt17.vue')
                },
                {
                    path: '/dt18',
                    component: () => import('../components/student/Personnel/DirectorSales/dt18.vue')
                },
                {
                    path: '/dt19',
                    component: () => import('../components/student/Personnel/DirectorSales/dt19.vue')
                },
                {
                    path: '/dt20',
                    component: () => import('../components/student/Personnel/DirectorSales/dt20.vue')
                },
                {
                    path: '/dt21',
                    component: () => import('../components/student/Personnel/DirectorSales/dt21.vue')
                },
                {
                    path: '/dt22',
                    component: () => import('../components/student/Personnel/DirectorSales/dt22.vue')
                },
                {
                    path: '/dt23',
                    component: () => import('../components/student/Personnel/DirectorSales/dt23.vue')
                },
                {
                    path: '/dt24',
                    component: () => import('../components/student/Personnel/DirectorSales/dt24.vue')
                },
                {
                    path: '/dt26',
                    component: () => import('../components/student/Personnel/DirectorSales/dt26.vue')
                },
                
                {
                    path: '/gm4',
                    component: () => import('../components/student/Personnel/Gmanager/gm4.vue'),
                },
               
                // 渠道管理
                {
                    path: '/NotificationOne',
                    component: () => import('../components/ChannelManagement//Notification/NotificationOne.vue'),
                },
                {
                    path: '/Notification1',
                    component: () => import('../components/ChannelManagement//Notification/Notification1.vue'),
                },
                {
                    path: '/Notification3',
                    component: () => import('../components/ChannelManagement//Notification/Notification3.vue'),
                },
                {
                    path: '/Notification2',
                    component: () => import('../components/ChannelManagement//Notification/Notification2.vue'),
                },
                // 渠道交付
                {
                    path:'/Notification4',
                    component: () => import('../components/ChannelManagement//Notification/Notification4.vue'),
                },
                // 渠道策略 -- 行业会展
                {
                    path:'/str1',
                    component:()=> import('../components/student/Strategy/str1.vue')
                },
                // 渠道策略 -- 经销商
                {
                    path:'/str2',
                    component:()=> import('../components/student/Strategy/str2.vue')
                },

                // 渠道策略 -- 直播
                {
                    path:'/str3',
                    component:()=> import('../components/student/Strategy/str3.vue')
                },

                // 电商平台
                {
                    path:'/str4',
                    component:()=> import('../components/student/Strategy/str4.vue')
                },
                // 渠道管理下市场销售
                {
                    path: '/dt5',
                    component: () => import('../components/student/Personnel/DirectorSales/dt5.vue')
                },
                // 运营售后
                {
                    path: '/NotificationTwo',
                    component: () => import('../components/ChannelManagement/Notification/NotificationTwo.vue'),
                },
                // 问题
                {
                    path:'/problem',
                    component: () => import('@/view/student/Problem.vue'),
                },
                {
                    path:"/score",
                    name:'score',
                    component:()=> import('../view/student/GetScore.vue'),
                },

            ]

        },
        {
            //专利申请
            path: '/applys',
            name: 'applys',
            component: () => import('../view/student/Applys'),
            children: [
                {
                    path: 'conent1',
                    component: () => import('../components/student/Applys/conent1')
                },
                {
                    path: 'ApplysEnTer',
                    component: () => import('../components/student/Applys/ApplysEnTer.vue')
                },
                {
                    path: 'ApplysEnTer2',
                    component: () => import('../components/student/Applys/ApplysEnTer2.vue')
                },
                {
                    path: 'conSpeak1',
                    component: () => import('../components/student/Applys/conSpeak1.vue')
                },
                {
                    path: 'conSpeak2',
                    component: () => import('../components/student/Applys/conSpeak2.vue')
                },
                {
                    path: 'conent2',
                    component: () => import('../components/student/Applys/conent2.vue')
                },
                {
                    path: 'conent22',
                    component: () => import('../components/student/Applys/conent22.vue')
                },
                {
                    path: 'conent3',
                    component: () => import('../components/student/Applys/conent3')
                },
                {
                    path: 'conent4',
                    component: () => import('../components/student/Applys/conent4')
                },
                {
                    path: 'conent5',
                    component: () => import('../components/student/Applys/conent5')
                },
                {
                    path: 'conent6',
                    component: () => import('../components/student/Applys/conent6')
                },
                {
                    path: 'conent7',
                    component: () => import('../components/student/Applys/conent7')
                },
                {
                    path: 'UtilityPatent1',
                    component: () => import('../components/student/Applys/UtilityPatent1.vue')
                },
                // 作品登记权
                {
                    path: '/WorkRegistration',
                    component: () => import('../components/student/Applys/WorkRegistration.vue')
                },
                // 软件著作权
                {
                    path: '/SoftwareWorks',
                    component: () => import('../components/student/Applys/SoftwareWorks.vue')
                },
                {
                    path: 'UtilityPatent2',
                    component: () => import('../components/student/Applys/UtilityPatent2')
                },
                {
                    path: 'UtilityPatent3',
                    component: () => import('../components/student/Applys/UtilityPatent3')
                },
                {
                    path: 'UtilityPatent4',
                    component: () => import('../components/student/Applys/UtilityPatent4')
                },
                {
                    path: 'UtilityPatent5',
                    component: () => import('../components/student/Applys/UtilityPatent5')
                },
                {
                    path: 'DesignPatent1',
                    component: () => import('../components/student/Applys/DesignPatent1')
                },
                {
                    path: 'DesignPatent2',
                    component: () => import('../components/student/Applys/DesignPatent2')
                },
                {
                    path: 'DesignPatent3',
                    component: () => import('../components/student/Applys/DesignPatent3')
                },
                {
                    path: 'DesignPatent4',
                    component: () => import('../components/student/Applys/DesignPatent4')
                },
                {
                    path: 'DesignPatent5',
                    component: () => import('../components/student/Applys/DesignPatent5')
                },
            ]
        },
        {
            // 公司注册
            path: '/company',
            name: 'company',
            component: () => import('../view/student/CompanyRegistry.vue'),
            children: [
                {
                    path: 'comRegist1',
                    component: () => import('../components/student/CompanyRegistry/comRegist1.vue')
                },
                {
                    path: 'comRegist2',
                    component: () => import('../components/student/CompanyRegistry/comRegist2.vue')
                },
                {

                    path: 'ComRegistEnter',
                    component: () => import('../components/student/CompanyRegistry/ComRegistEnter.vue')
                },
                {
                    path: 'comRegist3',
                    component: () => import('../components/student/CompanyRegistry/comRegist3.vue'),
                    children: [
                        {
                            path: 'crg',
                            component: () => import('../components/student/CompanyRegistry/crg.vue')
                        },
                        {
                            path: 'crg1',
                            component: () => import('../components/student/CompanyRegistry/crg1.vue')
                        },

                        {
                            path: 'crg2',
                            component: () => import('../components/student/CompanyRegistry/crg2.vue')
                        },
                        {
                            path: 'crg3',
                            component: () => import('../components/student/CompanyRegistry/crg3.vue')
                        },
                        {
                            path: 'crg4',
                            component: () => import('../components/student/CompanyRegistry/crg4.vue')
                        },
                        {
                            path: 'crg5',
                            component: () => import('../components/student/CompanyRegistry/crg5')
                        },
                        {
                            path: 'crg6',
                            component: () => import('../components/student/CompanyRegistry/crg6')
                        },
                        {
                            path: 'crg7',
                            component: () => import('../components/student/CompanyRegistry/crg7')
                        },
                        {
                            path: 'crg8',
                            component: () => import('../components/student/CompanyRegistry/crg8')
                        },
                        {
                            path: 'crg9',
                            component: () => import('../components/student/CompanyRegistry/crg9')
                        }
                    ]
                }
            ]
        },
        {
            // 产品研发生产
            path: '/production',
            name: 'production',
            component: () => import('../view/student/Production'),
            children: [
                {
                    path: 'prd1',
                    component: () => import('../components/student/Production/prd1')
                },
                {
                    path: 'prd2',
                    component: () => import('../components/student/Production/prd2.vue')
                }
            ]
        },
        {
            // 创业公司
            path: '/entrepreneurial',
            name: 'entrepreneurial',
            component: () => import('../view/student/EntrepreneurialFirm.vue'),
            children: [
                {
                    path: 'ef',
                    component: () => import('../components/student/EntrepreneurialFirm/ef.vue')
                },

            ]
        },
        {
            //角色分配和股权分配
            path: '/Distribute',
            name: 'Distribute',
            component: () => import('../view/student/Distribute'),
            children: [
                {
                    path: 'Distribute1',
                    component: () => import('../components/student/Distribute/distribute1.vue')
                },
                {
                    path: 'Distribute2',
                    component: () => import('../components/student/Distribute/distribute2.vue')
                },
            ]
        },
        // 公司注册
        {
            path: '/Incorporation',
            name: 'Incorporation',
            component: () => import('../view/student/Incorporation.vue'),
            children: [
                //银行卡公户办理
                {
                    path: 'banking',
                    component: () => import('../components/student/Incorporation/banking.vue')
                },
                //社保开户登记
                {
                    path: 'socialinsurance',
                    component: () => import('../components/student/Incorporation/socialinsurance.vue')
                },
                //刻章
                {
                    path: 'stamp',
                    component: () => import('../components/student/Incorporation/stamp.vue')
                },
                //税务登记
                {
                    path: 'taxregistration',
                    component: () => import('../components/student/Incorporation/taxregistration.vue')
                },
            ]
        },
        // 产品研发生产
        {
            path: "/Development",
            name: 'Development',
            component: () => import('../view/student/Development.vue'),
            children: [
                {
                    path: 'dev',
                    component: () => import('../components/student/Development/dev.vue')
                },
                {
                    path: 'dev1',
                    component: () => import('../components/student/Development/dev1.vue')
                },
                {
                    path: 'dev2',
                    component: () => import('../components/student/Development/dev2.vue')
                },
                {
                    path: '/dev2centent',
                    component: () => import('../components/student/Development/dev2centent.vue')
                },
                {
                    path: '/dev2centent1',
                    component: () => import('../components/student/Development/dev2centent1.vue')
                },
                {
                    path: '/dev2centent2',
                    component: () => import('../components/student/Development/dev2centent2.vue')
                },
                {
                    path: '/dev2centent3',
                    component: () => import('../components/student/Development/dev2centent3.vue')
                },

                {
                    path: 'dev3',
                    component: () => import('../components/student/Development/dev3.vue')
                },
                {
                    path: 'dev4',
                    component: () => import('../components/student/Development/dev4.vue')
                },
                {
                    path: '/dev5',
                    component: () => import('../components/student/Development/dev5.vue')
                },
                {
                    path: 'dev7',
                    component: () => import('../components/student/Development/dev7.vue')
                },
                {
                    path: 'dev8',
                    component: () => import('../components/student/Development/dev8.vue')
                },
                // 其他部件采购
                {
                    path: 'dev9',
                    component: () => import('../components/student/Development/dev9.vue')
                },
                
               
                
                {
                    path: '/historyPm4',
                    component: () => import('../components/student/Personnel/PurchasingManager/historyPm4.vue'),
                },
                // {
                //     path: '/pm8',
                //     component: () => import('../components/student/Personnel/PurchasingManager/pm8.vue'),
                // },
                //  代加工 合同
                {
                    path: '/pm5',
                    component: () => import('../components/student/Personnel/PurchasingManager/pm5.vue'),
                },
                // 采购合同
                {
                    path: '/dev10',
                    component: () => import('../components/student/Development/dev10.vue')
                },
                {
                    path: '/dev10centent',
                    component: () => import('../components/student/Development/dev10centent.vue')
                },
                // 加工计划
                {
                    path: '/dev11',
                    component: () => import('../components/student/Development/dev11.vue')
                },

                {
                    path: '/historyDev11',
                    component: () => import('../components/student/Development/historyDev11.vue')
                },
                // {
                //     path: '/dev2centent',
                //     component: () => import('../components/student/Development/dev2centent.vue')
                // },
                
                {
                    path: '/dev12centent',
                    component: () => import('../components/student/Development/dev12centent.vue')
                },
                // 产品自组装
                {
                    path: '/prd2',
                    component: () => import('../components/student/Production/prd2.vue')
                },
                // 产品研发
                {
                    path: '/dev13',
                    component: () => import('../components/student/Development/dev13.vue')
                },
                // 知识产权
                {
                    path: '/dev14',
                    component: () => import('../components/student/Development/dev14.vue')
                }

            ]
        },

        {
            //人员分工
            path: '/personnel',
            name: 'personnel',
            component: () => import('../view/student/Personnel'),
            children: [
                {
                    path: 'gmanager',       // 总经理
                    component: () => import('../components/student/Personnel/Gmanager/gmanager.vue'),
                    children: [
                        {
                            path: 'gm1',
                            component: () => import('../components/student/Personnel/Gmanager/gm1.vue'),
                        },
                        // 文本框
                        {
                            path: '/gm1from',
                            component: () => import('../components/student/Personnel/Gmanager/gm1from.vue'),
                        },
                        {
                            path: 'gm2',
                            component: () => import('../components/student/Personnel/Gmanager/gm2.vue'),
                        },
                        {
                            path: '/gm2From',
                            component: () => import('../components/student/Personnel/Gmanager/gm2From.vue'),
                        },
                        {
                            path: 'gm3',
                            component: () => import('../components/student/Personnel/Gmanager/gm3.vue'),
                        },
                        {
                            path: '/gm3From',
                            component: () => import('../components/student/Personnel/Gmanager/gm3From.vue'),
                        },
                        {
                            path: 'gm4',
                            component: () => import('../components/student/Personnel/Gmanager/gm4.vue'),
                        },
                        {
                            path: 'gm5',
                            component: () => import('../components/student/Personnel/Gmanager/gm5.vue'),
                        },
                        {
                            path: 'gm6',
                            component: () => import('../components/student/Personnel/Gmanager/gm6.vue'),
                        },

                        {
                            path: '/gm6from',
                            component: () => import('../components/student/Personnel/Gmanager/gm6from.vue'),
                        },
                        {
                            path: 'gm7',
                            component: () => import('../components/student/Personnel/Gmanager/gm7'),
                        },
                        {
                            path: 'gm8',
                            component: () => import('../components/student/Personnel/Gmanager/gm8'),
                        },

                        {
                            path: '/bankloan',      //银行贷款
                            component: () => import('../components/student/Personnel/Gmanager/BankLoan.vue'),
                        },
                        {
                            path: '/private',      //股东融资
                            component: () => import('../components/student/Personnel/Gmanager/PrivateEquity.vue'),
                        },
                        {
                            path: '/gomply',      //政府资助
                            component: () => import('../components/student/Personnel/Gmanager/GovernmentPolicySubsidy.vue'),
                        },
                        // {
                        //     path: 'bankloan',      //银行贷款
                        //     component: () => import('../components/student/Personnel/Gmanager/BankLoan.vue'),
                        // },
                        {
                            path: '/bankloanhistory',      //银行贷款历史
                            component: () => import('../components/student/Personnel/Gmanager/bankloanhistory.vue'),
                        },
                        // {
                        //     path: 'private',      //私募股权融资
                        //     component: () => import('../components/student/Personnel/Gmanager/PrivateEquity'),
                        // },
                        {
                            path: '/privatehistory',      //私募股权融资历史
                            component: () => import('../components/student/Personnel/Gmanager/PrivateEquityHistory.vue'),
                        },
                        {
                            path: 'privatedetails',      //私募股权融资历史
                            component: () => import('../components/student/Personnel/Gmanager/PrivateEquityDetails'),
                        },
                        // {
                        //     path: 'gomply',      //政府政策资助
                        //     component: () => import('../components/student/Personnel/Gmanager/GovernmentPolicySubsidy'),
                        // },
                        {
                            path: 'partcomp',      //参与比赛/论坛
                            component: () => import('../components/student/Personnel/Gmanager/ParticipateCompetition'),
                        }
                    ]
                },
                {
                    //financial administrator  财务负责人
                    path: 'fcast',
                    component: () => import('../components/student/Personnel/FinanceEmployeeCharge/fcast.vue'),
                    children: [
                        {
                            path: 'fec1',
                            component: () => import('../components/student/Personnel/FinanceEmployeeCharge/fec1.vue'),
                        },
                        {
                            path: 'fec2',
                            component: () => import('../components/student/Personnel/FinanceEmployeeCharge/fec2'),
                        },
                        {
                            path: 'fec3',
                            component: () => import('../components/student/Personnel/FinanceEmployeeCharge/fec3.vue'),
                        },
                        {
                            path: 'fec4',
                            component: () => import('../components/student/Personnel/FinanceEmployeeCharge/fec4'),
                        },
                        {
                            path: 'fec5',
                            component: () => import('../components/student/Personnel/FinanceEmployeeCharge/fec5.vue'),
                        },
                        {
                            path: 'fec6',
                            component: () => import('../components/student/Personnel/FinanceEmployeeCharge/fec6.vue'),
                        },
                        {
                            path: 'fec7',
                            component: () => import('../components/student/Personnel/FinanceEmployeeCharge/fec7.vue'),
                        },
                        {
                            path: '/fec7Example',
                            component: () => import('../components/student/Personnel/FinanceEmployeeCharge/fec7Example.vue'),
                        },
                        {
                            path: 'fec8',
                            component: () => import('../components/student/Personnel/FinanceEmployeeCharge/fec8'),
                        },
                        {
                            path: '/fec9',
                            component: () => import('../components/student/Personnel/FinanceEmployeeCharge/fec9.vue'),
                        },
                        {
                            path: 'fec10',
                            component: () => import('../components/student/Personnel/FinanceEmployeeCharge/fec10.vue'),
                        },
                        {
                            path: '/fec10From',
                            component: () => import('../components/student/Personnel/FinanceEmployeeCharge/fec10From.vue'),
                        },

                        {
                            path: 'fec11',
                            component: () => import('../components/student/Personnel/FinanceEmployeeCharge/fec11.vue'),
                        },
                        {
                            path: 'fec12',
                            component: () => import('../components/student/Personnel/FinanceEmployeeCharge/fec12.vue'),
                        },
                        {
                            path: '/fec12From',
                            component: () => import('../components/student/Personnel/FinanceEmployeeCharge/fec12From.vue'),
                        }
                    ]
                },
                {
                    //director of sales  销售负责人
                    path: 'dtfs',
                    component: () => import('../components/student/Personnel/DirectorSales/dtfs.vue'),
                    children: [
                        {
                            // 市场筹划弹窗
                            path: '/market1',
                            component: () => import('../components/student/Personnel/DirectorSales/market1.vue')
                        },
                        {
                            // 市场筹划弹窗
                            path: 'dtt1',
                            component: () => import('../components/student/Personnel/DirectorSales/dtt1.vue')
                        },
                        {
                            path: 'dt2',
                            component: () => import('../components/student/Personnel/DirectorSales/dt2.vue')
                        },
                        {
                            path: 'dt3',
                            component: () => import('../components/student/Personnel/DirectorSales/dt3.vue')
                        },
                        {
                            path: 'dt4',
                            component: () => import('../components/student/Personnel/DirectorSales/dt4')
                        },
                        {
                            path: 'dt5',
                            component: () => import('../components/student/Personnel/DirectorSales/dt5.vue')
                        },
                        {
                            path: 'dt6',
                            component: () => import('../components/student/Personnel/DirectorSales/dt6')
                        },
                        {
                            path: 'dt7',
                            component: () => import('../components/student/Personnel/DirectorSales/dt7')
                        },
                        {
                            path: 'dt8',
                            component: () => import('../components/student/Personnel/DirectorSales/dt8')
                        },
                        {
                            path: 'dt9',
                            component: () => import('../components/student/Personnel/DirectorSales/dt9.vue')
                        },
                        {
                            path: 'dt10',
                            component: () => import('../components/student/Personnel/DirectorSales/dt10.vue')
                        },
                        {
                            path: 'dt11',
                            component: () => import('../components/student/Personnel/DirectorSales/dt11.vue')
                        },
                        {
                            path: '/dt11OneView',
                            component: () => import('../components/student/Personnel/DirectorSales/dt11OneView.vue')
                        },
                        {
                            path: 'dt12',
                            component: (dt14) => import('../components/student/Personnel/DirectorSales/dt12.vue')
                        },
                        {
                            path: 'dt13',
                            component: () => import('../components/student/Personnel/DirectorSales/dt13.vue')
                        },
                        {
                            path: 'dt14',
                            component: () => import('../components/student/Personnel/DirectorSales/dt14.vue')
                        },
                        {
                            path: 'dt15',
                            component: () => import('../components/student/Personnel/DirectorSales/dt15')
                        },
                        {
                            path: 'dt16',
                            component: () => import('../components/student/Personnel/DirectorSales/dt16')
                        },
                        {
                            path: 'dt17',
                            component: () => import('../components/student/Personnel/DirectorSales/dt17.vue')
                        },
                        {
                            path: 'dt18',
                            component: () => import('../components/student/Personnel/DirectorSales/dt18.vue')
                        },
                        {
                            path: 'dt19',
                            component: () => import('../components/student/Personnel/DirectorSales/dt19.vue')
                        },
                        {
                            path: 'dt20',
                            component: () => import('../components/student/Personnel/DirectorSales/dt20.vue')
                        },
                        {
                            path: 'dt21',
                            component: () => import('../components/student/Personnel/DirectorSales/dt21.vue')
                        },
                        {
                            path: 'dt22',
                            component: () => import('../components/student/Personnel/DirectorSales/dt22.vue')
                        },
                        {
                            path: 'dt23',
                            component: () => import('../components/student/Personnel/DirectorSales/dt23.vue')
                        },
                        {
                            path: 'dt24',
                            component: () => import('../components/student/Personnel/DirectorSales/dt24.vue')
                        },
                        {
                            path: 'dt25',
                            component: () => import('../components/student/Personnel/DirectorSales/dt25.vue')
                        },
                        {
                            path:'dt27', // 售后
                            component:()=> import('../components/student/Personnel/DirectorSales/dt27.vue')
                        },
                    ]
                },
                {
                    //technical director  技术负责人
                    path: 'thdt',
                    component: () => import('../components/student/Personnel/TechnicalDirector/thdt.vue'),
                    children: [
                        {
                            path: 'td1',
                            component: () => import('../components/student/Personnel/TechnicalDirector/td1'),
                        },
                        {
                            path: 'td2',
                            component: () => import('../components/student/Personnel/TechnicalDirector/td2'),
                        },
                        {
                            path: '/td3',
                            component: () => import('../components/student/Personnel/TechnicalDirector/td3.vue'),
                        },
                        {
                            path: 'td4',
                            component: () => import('../components/student/Personnel/TechnicalDirector/td4.vue'),
                        },
                        {
                            path: 'td5',
                            component: () => import('../components/student/Personnel/TechnicalDirector/td5.vue'),
                        },
                        {
                            path: 'td6',
                            component: () => import('../components/student/Personnel/TechnicalDirector/td6'),
                        },
                        {
                            path: 'td7',
                            component: () => import('../components/student/Personnel/TechnicalDirector/td7'),
                        },
                        {
                            path: '/td9',
                            component: () => import('../components/student/Personnel/TechnicalDirector/td9'),
                        },
                    ]
                },
                {
                    //Head of personnel  人事负责人
                    path: 'hadfp',
                    component: () => import('../components/student/Personnel/HeadPersonnel/hadfp.vue'),
                    children: [
                        {
                            path: 'hp1',
                            component: () => import('../components/student/Personnel/HeadPersonnel/hp1.vue'),
                        },
                        {
                            path: '/hp1From',
                            component: () => import('../components/student/Personnel/HeadPersonnel/hp1From.vue'),
                        },
                        {
                            path: 'hp2',
                            component: () => import('../components/student/Personnel/HeadPersonnel/hp2.vue'),
                        },
                        {
                            path: '/hp2From',
                            component: () => import('../components/student/Personnel/HeadPersonnel/hp2From.vue'),
                        },
                        {
                            path: 'hp3',
                            component: () => import('../components/student/Personnel/HeadPersonnel/hp3'),
                        },
                        {
                            path: 'hp4',
                            component: () => import('../components/student/Personnel/HeadPersonnel/hp4'),
                        },
                        {
                            path: 'hp5',
                            component: () => import('../components/student/Personnel/HeadPersonnel/hp5.vue'),
                        },
                        {
                            path: 'hp6',
                            component: () => import('../components/student/Personnel/HeadPersonnel/hp6'),
                        },
                        {
                            path: 'hp7',
                            component: () => import('../components/student/Personnel/HeadPersonnel/hp7.vue'),
                        },
                        {
                            path: '/hp8zzh',
                            component: () => import('../components/student/Personnel/HeadPersonnel/hp8zzh.vue'),
                        },
                        {
                            path: '/hp8',
                            component: () => import('../components/student/Personnel/HeadPersonnel/hp8.vue'),
                        },
                    ]
                },
                {
                    //purchasing manager  采购负责人
                    path: 'phmg',
                    component: () => import('../components/student/Personnel/PurchasingManager/phmg.vue'),
                    children: [
                        {
                            path: 'pm1',
                            component: () => import('../components/student/Personnel/PurchasingManager/pm1'),
                        },
                        {
                            path: 'pm2',
                            component: () => import('../components/student/Personnel/PurchasingManager/pm2'),
                        },
                        {
                            path: 'pm3',
                            component: () => import('../components/student/Personnel/PurchasingManager/pm3'),
                        },
                        {
                            path: 'pm4',
                            component: () => import('../components/student/Personnel/PurchasingManager/pm4'),
                        },
                        {
                            path: 'pm5',
                            component: () => import('../components/student/Personnel/PurchasingManager/pm5'),
                        },
                        {
                            path: 'pm6',
                            component: () => import('../components/student/Personnel/PurchasingManager/pm6'),
                        },
                        {
                            path: 'pm7',
                            component: () => import('../components/student/Personnel/PurchasingManager/pm7'),
                        },
                        {
                            path: 'pm8',
                            component: () => import('../components/student/Personnel/PurchasingManager/pm8.vue'),
                        },
                        {
                            path: 'pm9',
                            component: () => import('../components/student/Personnel/PurchasingManager/pm9'),
                        },
                        {
                            path: 'pm10',
                            component: () => import('../components/student/Personnel/PurchasingManager/pm10'),
                        },
                        {
                            path: 'pm11',
                            component: () => import('../components/student/Personnel/PurchasingManager/pm11'),
                        },
                        {
                            path: 'pm12',
                            component: () => import('../components/student/Personnel/PurchasingManager/pm12'),
                        },
                        {
                            path: 'pm13',
                            component: () => import('../components/student/Personnel/PurchasingManager/pm13.vue'),
                        },
                        {
                            path: 'pm14',
                            component: () => import('../components/student/Personnel/PurchasingManager/pm14.vue'),
                        },
                        {
                            path: 'pm15',
                            component: () => import('../components/student/Personnel/PurchasingManager/pm15.vue'),
                        },
                        {
                            path: 'pm16',
                            component: () => import('../components/student/Personnel/PurchasingManager/pm16.vue'),
                        },
                        {
                            path: 'pm17',
                            component: () => import('../components/student/Personnel/PurchasingManager/pm17.vue'),
                        },
                    ]
                },
                {
                    //QuestionnaireSurvey 问卷调查
                    path: 'questSurvey',
                    component: () => import('../components/student/Personnel/QuestionnaireSurvey')
                },

            ]
        },
        // 代工采购
        {
            path:"/dgcg",
            name:'dgcg',
            component:()=> import('../view/student/Procurement.vue'),
            children:[
                {
                    path: '/pm13',
                    name:'pm13',
                    component: () => import('../components/student/Personnel/PurchasingManager/pm13.vue'),
                },
                {
                    path: '/pm4',
                    name: 'pm4',
                    component: () => import('../components/student/Personnel/PurchasingManager/pm4.vue'),
                },
                {
                    path: '/contract',
                    name: 'contract',
                    component: () => import('../components/student/dgcg/Contract.vue'),
                },
                {
                    path: '/dev9centent',
                    name:'dev9centent',
                    component: () => import('../components/student/Development/dev9centent.vue')
                },
                // 采购计划
                {
                    path: '/dev12',
                    name:'dev12',
                    component: () => import('../components/student/Development/dev12.vue')
                },
                {
                    path: '/notarrive',
                    name: 'notarrive',
                    component: () => import('../components/student/dgcg/NotArrive.vue'),
                },
            ]
        },
        // 产品研发
        {
            path:"/cpyf",
            name:'cpyf',
            component:()=> import('../view/student/GoodsProduction.vue'),
            children:[
                // 产品升级
                {
                    path:"/upgrada",
                    name:'upgrada',
                    component:()=> import('../components/student/cpyf/Upgradation.vue')
                }
            ]
        },
        



        //教师端
        {
            path: '/teacher',
            redirect: '/teacher/Courseinformation',
            component: () => import('../view/teacher/index.vue'),
            children: [
                {
                    path: '/Courseinformation',
                    component: () => import('../components/teacher/Courseinformation.vue')
                },
                {
                    path: '/Coursedetails',
                    component: () => import('../components/teacher/Coursedetails'),
                    children:[
                        {
                            path: '/TeamInfo',
                            component: () => import('../components/teacher/TeamInfo')
                        },
                    ]
                
                },
                {
                    path: 'Personalcenters',
                    component: () => import('../components/teacher/Personalcenters'),
                    
                
                },
                

            ]
        },
        // 经营成果
        {
            path: '/OperateOne',
            name: 'OperateOne',
            component: () => import('../view/ResultOfOperation/OperateOne.vue'),
            children: [
                {
                    path: '/OneOperate',
                    component: () => import('../components/ResultOfOperation/Notification/OneOperate.vue')
                },
            ]
        },

        {
            //首页可视化学生端
            path: '/Visualization',
            name: 'Visualization',
            component: () => import('../view/Visualization/index.vue'),
        },
        // 文件上传
        {
            //首页可视化学生端
            path: '/file',
            name: 'file',
            component: () => import('../view/student/file.vue'),
        },
        {
            path: '/404',
            component: () => import('../view/student/404')
        },
        // 未定义的路由，跳转到404
        {
            path: '/:catchAll(.*)',
            redirect: '/404'
        }

    ],
})

route.beforeEach((to, from, next) => {
    if (to.path == '/login' || to.path == '/regist') {
        next();
    } else {
        const token = sessionStorage.getItem('token'); // 获取token
        // token不存在
        if (token === null || token === '') {
            ElMessage.error('您还没有登录，请先登录')
            next('/login');
        } else {
            next();
        }
    }
});



export default route