import { createApp } from 'vue'
import App from './App.vue'
import route from './router'
import { createPinia } from 'pinia'
import piniaPluginPersist from 'pinia-plugin-persist'
// 引入elementPlus
import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'
import './assets/icon/iconfont.css'
// import "./utils/rem"
// import "./utils/newref"
// axios.interceptors.request.use(config => {
//     console.log(config);
//     config.headers.Authorization = window.sessionStorage.getItem('token');
//     // 在最后必须return 
//     return config
// })


// import 'lib-flexible'

createApp(App)
    .use(route)
    .use(createPinia().use(piniaPluginPersist))
    .use(ElementPlus)
    
    .mount('#app')
    

