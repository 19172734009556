<template>
    <!-- 浮窗 -->
    <div class="mask">
        <!-- 问卷调查 -->
        <div class="wrap" v-if="showWrap">
            <img
          src="../../../../assets//img/apply/错号.png"
          alt=""
          @click="close"
        />
            <div class="text">
                 <!-- 市场筹划 -->
            </div>
            <div class="box2">
                <span>默认创始人角色请分配小组成员角色</span>
            </div>
            <div class="box3">
                <span @click="showWrap = false"><p>是</p></span>
            </div>     
        </div>
        <personnelManagement v-if="!showWrap"/>
    </div>
</template>

<script setup>
import personnelManagement from "../Gmanager/gm6.vue";
import {ref,onMounted}  from "vue";
import {homeData} from "@/Pinia/Home";
import { useRouter } from "vue-router";
let store = homeData();
const router = useRouter()
console.log("这是pinia", store.bulidRole);

onMounted(()=>{
    store.setBulidRole(111222)
})


const close =()=> {
    // store.setBulidRole(false);
    router.push('/')
}
const showWrap = ref(true);
</script>
<style scoped>
.mask {
    width: 1920px;
    height: 1080px;
    background: rgba(0, 0, 0, 0.6);
    position: absolute;
    top: 0;
}

.wrap {
    width: 714px;
    height: 614px;
    background-image: url(../../../../assets/img/apply/弹窗.png);
    background-repeat: no-repeat;
    background-size: 100%;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
}

.wrap>img {
    width: 59px;
    height: 40px;
    position: absolute;
    top: 91px;
    right: 5px;
    cursor: pointer;
}

.wrap>div:nth-of-type(1) {
    position: absolute;
    top: 125px;
    left: 30px;
    width: 96%;
    margin: auto;
}

.wrap>div:nth-of-type(1)>span {
    width: 144px;
    height: 25px;
    font-size: 24px;
    font-family: Microsoft YaHei;
    font-weight: bold;
    color: #00A8FF;
}
.text{
    /* align-content: center; */
    color: white;
   
}

.box2 {
    width:582px;
    height: 311px;
    display: flex;
    position: absolute;
    left:90px;
    top: 190px;
}

.box2>span {
    font-size: 14px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #FFFFFF;
    cursor: pointer;
    font-size: 20px;
    margin-top: 30px;
    width: 582px;
    height: 311px;
}

.box3 {
    width: 80%;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    position: absolute;
    top: 100px;
}
.box3 img{
    width: 16px;
    height: 16px;
    margin-right: 8px;
}
.box3>span {
    display: flex;
    align-items: center;
    width: 100px;
    height: 30px;
    border: 1px solid #00A8FF;
    background-color: #00A8FF;
    border-radius: 4px;
    font-size: 16px;
    margin-top: 250px;
    margin-bottom: 30px;
    margin-left: 310px;
    margin-right: 10px;
    text-align: center;
}
.box3>span>p{
    margin-left: 40px;
    line-height: 60px;
    color: #FFFFFF; 
    font-weight: 600;
    text-align: center;
}
i {
    display: inline-block;
    width: 20px;
    height: 60px;
    font-size: 18px;
    font-family: Arial;
    font-weight: bold;
    color: #FFFFFF;
    line-height: 60px;
    margin-left: 20px;
    margin-right: 20px;
}

/* 点击切换样式 */
/* .cutstyle {
    background-image: url(../../../../assets/img/apply/框-蓝.png) !important;
} */
</style>
