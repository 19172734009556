<!-- 主页 -->
<template>
  <div class="home" v-if="isShow">
    <!-- 标题头 -->
    <div class="top-bar">
      <div class="top-bj">
        <header>
          <div class="logo-box">
            <img class="logo" src="../../assets/logo.png" />
          </div>

          <div class="course">
            <p>
              <span>课程码:</span><span>{{ course.code }}</span>
            </p>
            <p>
              <span>课堂名称:</span><span>{{ course.grade }}</span>
            </p>
            <p>
              <span>班级:</span><span>{{ course.name }}</span>
            </p>
            <p>
              <span>总人数:</span><span>{{ course.users }}</span>
            </p>
            <p>
              <span>小组:</span><span>{{ team.name }}</span>
            </p>
            <p>
              <span>当前得分:</span><span>{{ scoreData.value }}</span>
            </p>
          </div>

          <!-- 切换用户 -->
          <div class="icon">
            <span @click="intoScore" class="circle1"></span>
            <span @click="intoProblem" class="circle"></span>

            <span
              :class="isFullscreenImg ? 'smallcheckAll' : 'checkAll'"
              @click="changeScreenfull"
            ></span>

            <div class="user">
              <span class="defaultAvatar"></span>
              <div class="peoperName" style="display: flex">
                <span>{{ newstore.textCourse }}</span>
                <img src="../../assets/img/apply/xj.png" alt="" />
              </div>

              <div class="info">
                <span @click="gotouserInfo">个人中心</span>
                <span @click="gotoHistory">历史课程</span>
                <span @click="switching">切换课程</span>
                <span class="exit" @click="Logout()">退出登录</span>
              </div>
            </div>
          </div>
        </header>
      </div>
    </div>
    <!-- 文字 -->
    <div class="text">
      <span></span>
      <span> 创业虚拟仿真中心</span>
      <span></span>
    </div>
    <!-- 内容区 -->
    <div>
      <!-- 左侧栏 -->
      <div class="content-l">
        <div class="btn" :class="nodeControl.value.s1 ? 'isSelectBtn' : ''">
          <!-- :class="tid == 3 ? 'select' : 'noselect'" -->
          <div class="noselect">
            <el-button
              @click="tool4"
              :class="nodeControl.value.s1 ? 'selectBtn' : ''"
              :disabled="!nodeControl.value.s1"
              >团队组建
            </el-button>
          </div>
        </div>

        <div class="btn" :class="nodeControl.value.s2 ? 'isSelectBtn' : ''">
          <!-- :class="tid == 0 ? 'select' : 'noselect'" -->
          <div class="noselect">
            <el-button
              @click.stop="tool1"
              :class="nodeControl.value.s2 ? 'selectBtn' : ''"
              :disabled="!nodeControl.value.s2"
              >市场调研</el-button
            >
          </div>
        </div>

        <div class="btn" :class="nodeControl.value.s3 ? 'isSelectBtn' : ''">
          <!--  :class="tid == 6 ? 'select' : 'noselect'" -->
          <div class="noselect">
            <el-button
              @click="tool8"
              :class="nodeControl.value.s3 ? 'selectBtn' : ''"
              :disabled="!nodeControl.value.s3"
              class="text-only"
              >公司注册</el-button
            >
          </div>
        </div>

        <div class="btn" :class="nodeControl.value.s4 ? 'isSelectBtn' : ''">
          <!-- :class="tid == 5 ? 'select' : 'noselect'" -->
          <div class="noselect">
            <el-button
              @click.prevent="too20"
              :class="nodeControl.value.s4 ? 'selectBtn' : ''"
              :disabled="!nodeControl.value.s4"
              class="text-only"
              >产品研发</el-button
            >
          </div>
        </div>

        <div class="btn" :class="nodeControl.value.s5 ? 'isSelectBtn' : ''">
          <!-- :class="tid == 1 ? 'select' : 'noselect'" -->
          <div class="noselect">
            <el-button
              @click.capture="tool2"
              :class="nodeControl.value.s5 ? 'selectBtn' : ''"
              :disabled="!nodeControl.value.s5"
              class="text-only"
              >代工采购</el-button
            >
          </div>
        </div>

        <div class="btn" :class="nodeControl.value.s6 ? 'isSelectBtn' : ''">
          <!-- :class="tid == 5 ? 'select' : 'noselect'" -->
          <div class="noselect">
            <el-button
              @click="tool7"
              :class="nodeControl.value.s6 ? 'selectBtn' : ''"
              :disabled="!nodeControl.value.s6"
              class="text-only"
              >产品生产</el-button
            >
          </div>
        </div>

        <div class="btn" :class="nodeControl.value.s7 ? 'isSelectBtn' : ''">
          <!-- :class="tid == 2 ? 'select' : 'noselect'" -->
          <div class="noselect">
            <el-button
              @click="tool3"
              :class="nodeControl.value.s7 ? 'selectBtn' : ''"
              :disabled="!nodeControl.value.s7"
              class="text-only"
              >渠道策略</el-button
            >
          </div>
        </div>

        <span class="hide"></span>
        <div class="content-but">
          <p>当前：{{ newCurrentName }}</p>
          <div @click="tool9" class="nextSelect"><span>进入下一月</span></div>
        </div>
      </div>
      <!-- 右侧栏 -->
      <div class="content-r">
        <!-- 弹窗 -->
        <!-- 中间区域 -->
        <div class="content">
          <!-- 人员分布 -->
          <div class="box">
            <div class="box-title">
              <img src="../../assets/img/apply/管理人员.png" />
              <span>人员概况</span>
            </div>
          </div>
          <div class="box1">
            <div
              v-for="item in operateData.human"
              :key="item.role"
              @click="b_bts1(item.role)"
              :class="item.current ? 'peoperColor' : ''"
            >
              <!-- <span>{{ registeringRoles }}</span> -->
              <span class="username"
                ><i v-if="item.current"></i> {{ item.title }}</span
              >
              <!-- <span>{{ item.role }}</span> -->
              <div class="uploadPictures" @click.stop=""></div>

              <div class="img">
                <img
                  fit="cover"
                  class="profilePhoto"
                  :src="item.image"
                  v-if="!userInfo.headerImg"
                />
                <img
                  class="profilePhotoUpdata"
                  :src="userInfo.headerImg"
                  v-else
                />
              </div>
            </div>
          </div>
          <p class="title">
            <img src="../../assets/img/apply/month.png" alt="" />
            本月运营
          </p>
          <div class="box2 box4" style="cursor: pointer">
            <!-- 营业收入 -->
            <div @click="showMX(11)">
              <div class="numberSum">
                {{ comdify(operateData.methods[0].value) }}
                <span >元</span>
              </div>
              <span>{{ operateData.methods[0].title }}</span>
              
            </div>
            <!-- 营业支出 -->
            <div class="SalesMonth" @click="showMX(12)">
              <div class="numberSum">
                {{ comdify(operateData.methods[1].value) }}
                <span >元</span>
              </div>
              <span>{{ operateData.methods[1].title }}</span>
              
            </div>
            <!-- 已下单 -->
            <div class=lr @click="showMX(13)">
              <div class="textList">
                
                <p >
                  <span>核心部件</span
                  > : <span>
                    {{ operateData.methods[2].value['hx'] }}
          
                  </span> <span >件</span>
                </p>
                <p >
                  <span>其它部件</span
                  > : <span>
                    {{ operateData.methods[2].value['qt'] }}
          
                  </span> <span >件</span>
                </p>
                
              </div>
              <span class="lrT">{{ operateData.methods[2].title }}</span>
              
            </div>
            <!-- 生产中 -->
            <div class=lr @click="showMX(14)">
              <div class="textList">
                

                <p >
                  <span>P1</span
                    
                  > : <span>{{ operateData.methods[3].value['P1'] }}</span> <span >件</span>
                </p>
                <p >
                  <span>P2</span
                  > : <span>{{ operateData.methods[3].value['P2'] }}</span> <span >件</span>
                </p>
                <p >
                  <span>P3</span
                  > : <span>{{ operateData.methods[3].value['P3'] }}</span> <span >件</span>
                </p>
               
              </div>
              <span class="lrT">{{ operateData.methods[3].title }}</span>
              
            </div>
            <!-- 代价付 -->
            <div class=lr @click="showMX(15)">
              <div class="textList">
               

                <p >
                  <span>P1</span
                  > : <span>{{ operateData.methods[4].value['P1'] }}</span> <span >件</span>
                </p>
                <p >
                  <span>P2</span
                  > : <span>{{ operateData.methods[4].value['P2'] }}</span> <span >件</span>
                </p>
                <p >
                  <span>P3</span
                  > : <span>{{ operateData.methods[4].value['P3'] }}</span> <span >件</span>
                </p>
               
              </div>
              <span class="lrT">{{ operateData.methods[4].title }}</span>
              
            </div>
          </div>
          <p>
            <img src="../../assets/img/apply/运营情况.png" alt="" />
            总体经营
          </p>

          <div class="box2" style="cursor: pointer">
            <!-- 资金余额 -->
            <div @click="showMX(1)">
              <div class="numberSum">
                {{ comdify(operateInfo[0].value) }}
                
                <span >元</span>
              </div>
              <span>{{ operateInfo[0].title }}</span>
              
            </div>
            <!-- 销售总额 -->
            <div class="SalesMonth" @click="showMX(2)">
              <div class="numberSum">
                {{ comdify(operateInfo[1].value) }}
                <span >元</span>
              </div>
              <span>{{ operateInfo[1].title }}</span>
              
            </div>
            <!-- 产品销量 -->
            <div class="lr" @click="showMX(3)">
              <div class="textList">
                <p
                 
                  v-for="item in operateInfo[2].value"
                  :key="item.name"
                >
                  <span>{{ item.name.split(":")[0] }}</span
                  > : <span>{{ item.value }}</span> <span >件</span>
                </p>
              </div>
              <span class="lrT">{{ operateInfo[2].title }}</span>
              
            </div>
            <div class="lr">
              <div class="textList">
                <p v-for="item in operateInfo[3].value" :key="item.name">
                  <span>{{ item.name.split(":")[0] }}</span
                  > : <span>{{ item.value }}</span> <span >元</span>
                </p>
              </div>
              <span class="lrT">{{ operateInfo[3].title }}</span>
              
            </div>
            <!-- 净利润 -->
            <div @click="showMX(5)">
              <div class="numberSum">
                {{ comdify(operateInfo[4].value ) }}
                <span >元</span>
              </div>
              <span>{{ operateInfo[4].title }}</span>
              
            </div>
          </div>
          <div class="box3 box2">
            <!-- 产品库存 -->
            <div class="lr" @click="showMX(7)">
              <div class="textList">
                <p
                  
                  v-for="item in operateInfo[6].value"
                  :key="item.name"
                >
                  <span>{{ item.name.split(":")[0] }}</span
                  > : <span>{{ item.value }}</span> <span >件</span>
                </p>
              </div>
              <span class="lrT">{{ operateInfo[6].title }}</span>
             
            </div>
            <!-- 不见库存 -->
            <div @click="showMX(8)">
              <div class="numberSum">
                {{ operateInfo[7].value }} <span >件</span>
              </div>
              <span>{{ operateInfo[7].title }}</span>
              
            </div>

            <!-- 获得投资 -->
            <div @click="showMX(9)">
              <div class="numberSum">
                {{ comdify(operateInfo[8].value ) }}
                <span>元</span>
              </div>
              <span>{{ operateInfo[8].title }} </span>
              
            </div>
            <!-- 贷款负载 -->
            <div @click="showMX(10)">
              <div class="numberSum">
                {{ comdify(operateInfo[9].value ) }}
                <span>元</span>
              </div>
              <span>{{ operateInfo[9].title }}</span>
              
            </div>

            <!-- 政府补贴 -->
            <div @click="showMX(6)">
              <div class="numberSum">
                {{ comdify(operateInfo[5].value ) }}
                <span >元</span>
              </div>
              <span>{{ operateInfo[5].title }}</span>
              
            </div>
          </div>
        </div>
      </div>
    </div>
    
  </div>
  <!-- 二级路由出口 -->
  <router-view></router-view>
</template>
<script setup>
// import marketAnalysis from '../../components/student/Personnel/DirectorSales/dtt1.vue';
import {
  ref,
  onMounted,
  onBeforeMount,
  onBeforeUnmount,
  reactive,
  watch,
} from "vue";
import * as echarts from "echarts";
import { useRouter, useRoute } from "vue-router";
import { ElMessage, ElMessageBox } from "element-plus";
import { ElLoading } from "element-plus";
import axios from "axios";
import { useAxios } from "@/api";
// 弹窗 --默认创始人角色请分配小组成员角色
import one from "../../components/student/Personnel/DirectorSales/dt1.vue";
import { homeData } from "@/Pinia/Home";
import { storeToRefs } from "pinia";
import { control } from "../../Pinia/model/index.js";
import { courseHome } from "../../Pinia/mdelHome/CourseHome.js";
import { toFixedFun,comdify } from "@/stater/fun.js";

// import { storeToRefs } from "pinia";
let newstore = courseHome();
// let { textCourse } = storeToRefs(newstore);

let store = control();
let { registeringRoles } = storeToRefs(store);
const https = useAxios();

const storeHome = homeData();

import screenfull from "screenfull";

const showButton = ref(false); // 控制按钮的显示与隐藏

let operateInfo = reactive([]);

var isFullscreen = true;
var isFullscreenImg = ref(false);

var router = useRouter();
var route = useRoute();
var isShow = ref(false);
var selectColor = ref(route.query.id);
var router = useRouter();
var tid = ref(3); // 用于判断左侧栏是否改变选中
const userInfo = ref({
  headerImg: "",
  headerImg1: "",
  headerImg2: "",
  headerImg3: "",
  headerImg4: "",
  headerImg5: "",
});

// 监听storeHome.refreshHome操作首页是否更新， true 则更新， false则不更新
// 该数据默认为false ，当更新为true时直接转为false
// 该数据位置  @/Pinia/Home
watch(
  () => storeHome.refreshHome,
  (newval, oldval) => {
    if (newval) {
      homeLink2();
      storeHome.setRefreshHome(false);
    }
  }
);

onMounted(() => {
  // newstore.GET_COURSE;
  
  homeLink2();
  if (screenfull.isEnabled) {
    screenfull.on("change", change); //监听全屏切换或错误事件
    // screenfull.toggle();
  }

  
});
function loadingImg(res) {
  let loading;
  if (res) {
    loading = ElLoading.service({
      lock: true,
      text: "Loading",
      background: "rgba(0, 0, 0, 0.7)",
    });
  } else {
    loading = ElLoading.service({
      lock: true,
      text: "Loading",
      background: "rgba(0, 0, 0, 0.7)",
    });
    loading.close();
  }
}

// 切换课程
let switching = () => {
  store.UP_SWITCHING(false);
  router.push("/scene");
};

// 个人中心
const gotouserInfo = () => {
  router.push("/userinfo");
};

// 历史记录
const gotoHistory = () => {
  router.push("/history");
};

const token = { token: sessionStorage.getItem("token") };
const uploadSectionFile = (param) => {
  loadingImg(true);
  const formData = new FormData();
  formData.append("file", param.file);
  axios
    .post("https://api.evsp.ibeisai.com/v5/file/seg", formData)
    .then((res) => {
      if (res.data.code === 0) {
        loadingImg(false);
        ElMessage({
          type: "success",
          message: "上传成功",
        });
        userInfo.value.headerImg = res.data.data;
      } else {
        ElMessage({
          type: "warning",
          message: res.message,
        });
      }
    });
};

const changeScreenfull = function () {
  if (!screenfull.isEnabled) {
    //浏览器是否允许全屏
    ElMessage.error("错误");
    return false;
  }

  screenfull.toggle(); //在全屏和非全屏之间切换
};


const change = () => {
  isFullscreen = screenfull.isFullscreen;
  isFullscreenImg.value = isFullscreen;
};

// 组件卸载之前
onBeforeUnmount(() => {
  if (screenfull.isEnabled) {
    screenfull.off("change", change); //移除注册的事件
  }
});

const b_bts1 = (item) => {
  let role = item;

  switch (item) {
    case 1:
      selectColor.value = 1;
      stock("总经理");
      if (selectColor.value == 1) {
        router.push({
          path: "/personnel/gmanager",
          query: { id: 1 },
        });
      }
      break;
    case 2:
      selectColor.value = 2;
      stock("财务负责人");
      if (selectColor.value == 2) {
        router.push({
          path: "/personnel/fcast",
          query: { id: 2 },
        });
      }
      break;
    case 3:
      selectColor.value = 3;
      stock("销售负责人");
      router.push({
        path: "/personnel/dtfs",
        query: { id: 3 },
      });
      break;
    case 4:
      selectColor.value = 4;
      stock("技术负责人");
      router.push({
        path: "/personnel/thdt",
        query: { id: 4 },
      });
      break;
    case 5:
      selectColor.value = 5;
      stock("采购负责人");
      router.push({
        path: "/personnel/phmg",
        query: { id: 5 },
      });

      break;
    case 6:
      selectColor.value = 6;
      stock("人事行政负责人");
      router.push({
        path: "/personnel/hadfp",
        query: { id: 6 },
      });
      break;
    default:
      console.log("联系开发者");
      break;
  }
};

let upRole = ref({});
const upImg = (item) => {
  upRole.value = item;
};

//左侧栏事件
// 市场分析
const tool1 = () => {
  // analysis.value = true;
  stock("市场环境分析");
  tid.value = 0;
  router.push({
    path: "/dtt1",
    // query: { count: 4 },
  });
};
const tool2 = () => {
  stock("企业融资");
  tid.value = 1;
  router.push({
    path: "/dgcg",
    // query: { count: 1 },/Oem1    
  });
};
const tool3 = () => {
  stock("渠道策略");
  tid.value = 2;
  router.push({
    path: "/Notification1",
    // query: { isMarket: 3, count: 2 },
  });
};
const tool4 = () => {
  stock("创业团队组建");
  tid.value = 3;
  router.push({ path: "/dt1" });
  // router.push({
  //   path: "/entrepreneurial/ef",
  //   query: { isMarket: 0, count: 2 },
  // });
};
const tool5 = () => {
  stock("经营成果");
  tid.value = 4;
  router.push({
    path: "/OperateOne",
    // query: { count: 5 },
  });
};
const tool7 = () => {
  stock("产品生产");
  tid.value = 6;
  // router.push('/cpsc')
  router.push({
    path: "/entrepreneurial/ef",
    query: { isMarket: 2, count: 5 },
  });
};
const too20 = () => {
  stock("产品研发");
  tid.value = 6;
  // router.push({
  //   path: "/dev2",
  //   query: { isMarket: 1, count: 2 },
  // });
  router.push('/cpyf')
  // router.push({
  //   path: "/entrepreneurial/ef",
  //   query: { isMarket: 0, count: 2 },
  // });
  //   router.push({
};
const tool8 = () => {
  stock("公司注册");
  tid.value = 7;
  router.push({
    path: "/company/comRegist1",
    query: { isMarket: 1, count: 1 },
  });
};
const tool9 = async () => {
  await nextSeason();
};

const nextSeason = () => {
  let courseId = localStorage.getItem("courseid");
  https.post("/course/next", { id: courseId }).then((res) => {
    if (res.data.success) {
      ElMessage({
        message: res.data.message,
        type: "success",
      });

      tid.value = 8;
      router.push({});
      storeHome.setRefreshHome(true);
      homeLink2();
    } else {
      ElMessage.error(res.data.message);
    }
  });
};

// 退出登录
const Logout = () => {
  let token = sessionStorage.getItem("token");

  https.post("/user/logout", {}).then((res) => {
    let { message, success } = res.data;
    if (success) {
      sessionStorage.removeItem("token");
      router.push("/login");
    }
    ElMessage.error(message + "退出");
  });
};

function stock(text) {
  sessionStorage.setItem("centerTitle", text);
}

function init() {
  // 基于准备好的dom，初始化echarts实例
  var myChart = echarts.init(main.value);
  // 指定图表的配置项和数据
  var option = {
    xAxis: {
      type: "category",
      data: ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"],
      axisTick: {
        show: false, //是否显示网状线 默认为true
        alignWithLabel: true,
      },
      axisLabel: {
        show: true,
        textStyle: {
          color: "#ffffff", //更改坐标轴文字颜色
          fontSize: 10, //更改坐标轴文字大小
        },
      },
      axisLine: {
        show: true, //这里的show用于设置是否显示x轴那一条线 默认为true
        lineStyle: {
          color: "#ffffff", //更改坐标轴颜色
          width: 0,
        },
      },
    },
    yAxis: {
      type: "value",
      axisLabel: {
        show: true,
        textStyle: {
          color: "#ffffff", //更改坐标轴文字颜色
          fontSize: 12, //更改坐标轴文字大小
        },
      },
      axisLine: {
        show: true, //这里的show用于设置是否显示y轴那一条线 默认为true
        lineStyle: {
          //lineStyle里面写y轴那一条线的样式
          color: "#ffffff",
          width: 1, //轴线的粗细 我写的是2 最小为0，值为0的时候线隐藏
        },
      },
    },
    title: {
      left: "center",
      // text: '销售额',
      textStyle: {
        color: "#fff",
      },
    },
    series: [
      {
        data: [0, 100, 200, 320, 100, 250, 300],
        type: "line",
        itemStyle: {
          normal: {
            color: "#1bdaf8", //折线点的颜色
            lineStyle: {
              color: "#FFFFFF", //折线的颜色
            },
          },
        },
      },
    ],
    grid: {
      // show: true,
      top: "10%",
      left: "10%",
      bottom: "27%",
      containLabel: true,
    },
  };
  // 使用刚指定的配置项和数据显示图表。
  myChart.setOption(option);
}

// 运营情况数据

let operateData = reactive({
  operate: [],
  human: [],
  methods:[]
});

// 节点控制s1-s7变量
let nodeControl = reactive({
  value: [],
});

let scoreData = reactive({
  value:'',
  table:[]
})
let newCurrentName = ref("");
let nodeBoole = ref(false);
let course = reactive({});
let team = reactive({});
// 弹出框
var notification = ref(null);
const homeLink2 = () => {
  let homelinkId = localStorage.getItem("courseid");
  https.post("/course/home", { id: homelinkId }).then((res) => {
    if (res.data.code == -3002) {
      store.UP_SWITCHING(false);
      router.push("/scene");
      return false;
    }

    if (res.data.success) {
      operateData.human = [];
      operateData.operate.push(res.data.data.busine);
      // operateData.methods = [...res.data.data.monthl]
      momthlData(res.data.data.monthl)
  
      dataInfo(res.data.data.busine);
      course = res.data.data.course;
      team = res.data.data.team;
      newCurrentName.value = res.data.data.currentName;
      nodeControl.value = res.data.data.handleStep;
      let stateHandle = res.data.data.handleRole; //角色控制
      newstore.setTextCourse(res.data.data.name);
 
      store.UP_HANDLE(stateHandle);

      // 小组id
      sessionStorage.setItem("userid", res.data.data.team.id);
      notification.value = res.data.data.setrole;
      storeHome.setBulidRole(res.data.data.setrole);

      
      operateData.human = [...res.data.data.staff];

      scoreData.value = res.data.data.score.value;
      scoreData.table = res.data.data.score.table;
      storeHome.setScoreData(res.data.data.score.table)

      isShow.value = true;
    } else {
      ElMessage.error(res.data.message);
    }
  });
};

// 数据明细

const dataInfo = (data) => {
  operateInfo = [];
  for (let i = 1; i <= 10; i++) {
    operateInfo.push(data["b" + i]);
  }


};

// 本月运营
const momthlData = (data) => {

  operateData.methods = [];
  for(let i = 1; i <= 5; i++) {
    operateData.methods.push(data['m'+i])
  }


}

// 显示明细
const showMX = (id) => {
  
  stock("渠道策略");
  tid.value = 2;
  router.push({
    path: "/Balance",
    query: { type: id },
  });
};

const intoProblem = () => {
  router.push('/problem')
}

const intoScore = () => {
  router.push('/score')
}
</script>

<style lang="scss" scoped>
.top-bar {
  display: flex;
  justify-content: space-between;
  padding: 0px 7px;

  .top-bj {
    width: 100%;
    height: 150px;
    background-image: url(../../assets/img/CBP/顶部背景1.png);
    background-repeat: no-repeat;
    background-size: 100% 100%;

    header {
      width: 100%;
      height: 50%;
      padding: 20px 17px 9px 60px;
      display: flex;
      justify-content: space-between;

      .logo-box {
        width:400px;
      }
      .logo {
        height: 100%;
      }

      .course {
        width: 50%;
        height: 100%;

        display: flex;
        justify-content: space-between;
        align-items: center;
        text-align: center;

        p {
          font-size: 18px;
          color: #0fd8ed;
          font-weight: 600;

          span {
            margin: 0 6px;
          }
        }
      }

      .icon {
        display: flex;
        height: 100%;
        align-items: center;
        text-align: center;

        .circle {
          width: 30px;
          height: 30px;
          display: inline-block;
          background-image: url(../../assets/img/CBP/306.png);
          background-repeat: no-repeat;
          background-size: 100%;
          cursor: pointer;
          margin: 0 14px;
        }

        .circle1 {
          width: 36px;
          height: 36px;
          display: inline-block;
          background-image: url(../../assets/img/CBP/score.png);
          background-repeat: no-repeat;
          background-size: 100%;
          cursor: pointer;
          margin: 0 14px;
        }

        .checkAll {
          width: 25px;
          height: 25px;
          display: inline-block;
          background-image: url(../../assets/img/CBP/全屏\ .png);
          background-repeat: no-repeat;
          background-size: 100%;
          margin: 0 14px;
          cursor: pointer;
        }

        .smallcheckAll {
          width: 25px;
          height: 25px;
          display: inline-block;
          background-image: url(../../assets/img/CBP/退出全屏.png);
          background-repeat: no-repeat;
          background-size: 100%;

          cursor: pointer;
        }

        .user {
          display: flex;
          justify-content: center;
          margin-left: 14px;
          position: relative;

          .defaultAvatar {
            width: 30px;
            height: 30px;
            display: inline-block;
            background-image: url(../../assets/img/CBP/默认头像.png);
            background-repeat: no-repeat;
            background-size: 100%;
            margin-right: 3px;
            cursor: pointer;
            margin-right: 6px;
          }

          .peoperName {
            font-size: 20px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            color: #add7f4;
            cursor: pointer;
            display: flex;
            align-items: center;

            img {
              width: 20px;
              height: 20px;
              margin-left: 6px;
            }
          }

          .info {
            width: 106px;
            background: #f6f6f6;
            font-size: 20px;
            z-index: 999;
            padding: 10px 0;
            display: none;
            position: absolute;
            top: 35px;
            left: 30px;

            &::before {
              content: " ";
              position: absolute;
              border-top: 10px solid transparent;
              border-left: 10px solid transparent;
              border-right: 10px solid transparent;
              border-bottom: 10px solid #f6f6f6;
              top: -13px;
              left: 50%;
              transform: translate(-50% ,0);
            }

            span {
              display: inline-block;
              width: 100%;
              line-height: 40px;
              font-size: 14px;
              font-family: Microsoft YaHei;
              font-weight: 400;
              color: #333333;
              cursor: pointer;

              &:last-child {
                color: #d7000f;
              }

              &:hover {
                background-color: #ccc;
              }
            }
          }

          &:hover > .info {
            display: block;
          }
        }
      }
    }
  }
}

.back {
  width: 84px;
  height: 83px;
  display: inline-block;
  background-image: url(../../assets/img/CBP/返回按钮.png);
  background-repeat: no-repeat;
  background-size: 100%;
  position: absolute;
  top: 18px;
  left: 40px;
  cursor: pointer;
}

/* text */

.text {
  width: 100%;
  height: 100px;
  position: absolute;
  top: 63px;
  left: 0px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding-left: 230px;

  span:nth-child(1) {
    width: 412px;
    height: 12px;
    background-image: url(../../assets/img/CBP/圆装饰左.png);
    background-repeat: no-repeat;
    background-size: 100%;

    opacity: 0;
  }

  span:nth-child(2) {
    width: 639px;
    height: 44px;
    font-size: 44px;
    color: #0fd8ed;
    font-family: Source Han Serif CN;
    font-weight: bold;
  }

  span:nth-child(3) {
    width: 412px;
    height: 12px;
    background-image: url(../../assets/img/CBP/圆装饰右.png);
    background-repeat: no-repeat;
    background-size: 100%;

    opacity: 0;
  }
}

.content-l {
  width: 257px;
  height: 945px;
  background-image: url(../../assets/img/CBP/左侧框.png);
  background-repeat: no-repeat;
  background-size: 100%;
  position: absolute;
  top: 132px;
  left: 9px;
  overflow: hidden;
}

/* 手抓 */
.content-l > div,
.bts > div,
.cent > div,
ul > div {
  cursor: pointer;
}

/* 选中或者没选中*/
.select {
  background-image: url(../../assets/img/CBP/框-选中.png);
  background-repeat: no-repeat;
  background-size: 100%;
}

.noselect {
  background-image: url(../../assets/img/CBP/框-默认.png);
  background-repeat: no-repeat;
  background-size: 100%;
}

.nextSelect {
  position: absolute;
  bottom: 20px;
  left: 50px;
  background-image: url(../../assets/img/CBP/按钮.png);
  background-repeat: no-repeat;
  background-size: 100%;
  width: 180px;
  height: 100px;
}

.content-l .isSelectBtn > div:hover {
  background-image: url(../../assets/img/CBP/框-选中.png);
  background-repeat: no-repeat;
  background-size: 100%;
}

.content-l > div button {
  background-color: transparent;
  border: none;
  font-size: 20px;
  color: #666;
  width: 100%;
  height: 100%;
}

.content-l .btn > div {
  width: 179px;
  height: 70px;
  margin: 26px auto;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.content-l > div .selectBtn {
  color: #fff;
}

:deep .el-button.is-disabled,
.el-button.is-disabled:focus,
.el-button.is-disabled:hover {
  background: transparent;
  color: #666;
}

.content-l > div:nth-of-type(1) {
  margin-top: 45px;
}
content-but .content-but {
  position: absolute;
  bottom: 16px;
  left: 22px;
}

.content-but > p {
  // margin-left: 60px;
  margin-top: 15px;
  height: 21px;
  font-size: 20px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #ffffff;
}

.content-but > div {
  margin-top: 15px;
  text-align: center;
}

.content-l > .hide {
  display: inline-block;
  /* 30px 当财务分析存在时 为30 不存在时为106 */
  height: 30px;
  width: 222px;
  background-color: transparent;
  margin-left: 25px;
  /* margin-top: 5px; */
  border-top: 2px solid #0b75a2;
  border-bottom: 2px solid #0b75a2;
  box-shadow: 0px 1px #0b75a2;
}

.content-l span {
  display: block;
  width: 100%;
  height: 70px;

  font-size: 20px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #cff8fc;

  display: flex;
  justify-content: center;
  align-items: center;
  // margin-left: 10px;
}

.content {
  width: 1631px;
  height: 902px;
  background-image: url(../../assets/img/CBP/中间背景1.png);
  background-repeat: no-repeat;
  background-size: 100%;
  position: absolute;
  top: 148px;
  left: 267px;
}

.box {
  display: flex;
  justify-content: space-between;
  margin: 20px 42px;
}

.box-title {
  display: flex;
  width: 400px;
}

.box-title > img {
  width: 25px;
  height: 24px;
  margin-right: 6px;
}

.box-title > span {
  display: inline-block;
  width: 108px;
  font-size: 22px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #ffffff;
}

.box1 {
  width: 95%;
  display: flex;
  justify-content: space-between;
  position: absolute;
  left: 42px;
}

.box1 > div {
  width: 240px;
  // height: 310px;
  height: 240px;
  background-image: url(../../assets/img/apply/默认.png);
  background-repeat: no-repeat;
  background-size: 100%;
  cursor: pointer;
}

.box1 > div:hover > .uploadPictures {
  display: block;
}
.uploadPictures > img {
  width: 100%;
}
.box1 > div {
  position: relative;
}

.box1 span {
  width: 100%;
  font-size: 16px;
  font-family: Microsoft YaHei;
  font-weight: 600;
  color: #cbf9fe;
  position: absolute;
  bottom: 11px;
  left: 0;
}

.CloudUpload {
  width: 37px;
  height: 37px;
  position: absolute;
  left: 27px;
  top: 30px;
}

.profilePhoto {
  width: 70%;
  /* height: 220px; */
  /* position: absolute;
 top: 50%;
 left: 50%;
 transform: translate(-50%,-50%); */
}

.img {
  width: 100%;
  height: 86%;
  box-sizing: border-box;
  /* padding: 10px 10px 45px 10px; */
  overflow: hidden;
  padding-top: 30px;
}

.box1 > div:hover img {
  /* transform: scale(1.2); */
  /* transform-origin: 280% 300%; */
  transform: scale(1.2);
}

.profilePhotoUpdata {
  height: 220px;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
}

.uploadPictures {
  position: absolute;
  display: none;
}

.box1 > .peoperColor {
  transform: scale(1.05, 1.05);
  box-shadow: rgb(131, 129, 196) 0px 0px 20px;
  border-radius: 10px;
}

.content > p {
  font-size: 22px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #ffffff;
  position: absolute;
  top: 525px;
  left: 45px;
}

.content >.title{
  top:337px
}

p > img {
  width: 22px;
  height: 22px;
}

.box2 {
  width: 97%;
  display: flex;
  justify-content: space-evenly;
  justify-content: space-between;
  position: absolute;
  top: 560px;
  left: 35px;
}

.box3 {
  top: 710px;
}

.box4 {
  top:370px

  
}

.box4 > div {
    background-image: url(../../assets/img/apply/框2.png) !important;
  }

.box2 > div {
  // width: 287.4px;
  width: 300px;
  height: 138px;
  background-repeat: no-repeat;
  background-size: 100%;
  position: relative;
  // margin-right: 32px;
  background-image: url(../../assets/img/apply/框1.png);
  cursor: pointer;
}

.box2 > div:last-child {
  margin-right: 0;
}

.numberSum {
  font-size: 36px;
  font-family: Arial;
  font-weight: 400;
  color: #e8fcfe;
  position: absolute;
  width: 100%;
  text-align: right;
  position: absolute;
  bottom: 24px;
  padding-right: 50px;

  span {
    font-size: 18px;
  }
}



.textList {
  box-sizing: border-box;
  width: 60%;
  color: #e8fcfe;
  position: absolute;
  bottom: 16px;
  right: 40px;




  p {
    box-sizing: border-box;
    
    font-size: 16px;
    text-align: right;
    line-height: 1.5;
    // width: 49%;
  }
}
.box2 > div > span {
  font-size: 20px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #e8fcfe;
  position: absolute;
  top: 20px;
  left: 33px;
}

#circle {
  width: 90px;
  height: 90px;
  border: 4px solid #fff;
  opacity: 0.3;
  border-radius: 50%;
  position: absolute;
  top: 25px;
  left: 165px;
  /* z-index: 555; */
}

#circle > span {
  position: absolute;
  top: 35px;
  left: 22px;
  font-size: 22px;
  line-height: 22px;
  font-weight: 500;
  color: #fff;
}

#circles {
  width: 90px;
  height: 90px;
  border: 4px solid #fff;
  opacity: 0.3;
  border-radius: 50%;
  position: absolute;
  top: 25px;
  left: 165px;
}

#circles > span {
  position: absolute;
  top: 35px;
  left: 22px;
  font-size: 22px;
  line-height: 22px;
  color: #fff;
}

.box2 .operImg > img {
  width: 89px;
  height: 76px;
}

.box2 .operImgs {
  position: absolute;
  top: 30px;
  left: 176px;
}

.operImg1 > img {
  width: 78px;
  height: 88px;
  position: absolute;
  top: 3px;
  left: 8px;
}

.operImg2 > img {
  width: 69px;
  height: 72px;
  position: absolute;
  top: 3px;
  left: 11px;
}

.operImg3 > img {
  width: 63px;
  height: 78px;
  position: absolute;
  top: 3px;
  left: 11px;
}

.operImg4 > img {
  width: 83px;
  height: 83px;
  position: absolute;
  top: 3px;
  left: 5px;
}

.operImg5 > img {
  width: 91px;
  height: 77px;
  position: absolute;
  top: 3px;
  left: 0px;
}

.SalesMonth:hover > .echarts {
  display: block;
}

.box2 .echarts {
  width: 400px;
  height: 270px;
  color: #ffffff;
  background: url(../../assets/img/apply/canvasbackground.png);
  background-repeat: no-repeat;
  background-size: 100%;
  z-index: 999;
  opacity: 0.9;
  position: relative;
  top: -30%;
  left: 100%;
  display: none;
}
/* .home{
  position: relative;
} */
/* 弹框 */
section {
  position: fixed;
  top: 300px;
  left: 300px;
  transform: translate(-50%, -50%);
  width: 500px;
  height: 500px;
  background-color: aqua;
}

.el-button.text-only {
  background: none;
  border: none;
  color: inherit;
  padding: 0;
  font-size: 20px;
  font-family: Microsoft YaHei;
  /* background-color: red; */
  width: 100%;
  height: 100%;
  /* color: #cff8fc; */
  /* margin-left: 3px; */
  /* margin-top: 12px; */
}

.el-button.text-only:hover {
  background: none;
}
.el-button.text-only:primary-focus {
  background: transparent; /* 将背景色设置为透明 */
}

:deep(.el-button > span) {
  margin-bottom: 12px;
  margin-left: 3px;
}

.image-container {
  margin-left: 15px;
  margin-top: 7px;
  transform: scale(2);
}

.hideBtn {
  cursor: auto !important;
}

.hideBtn:hover {
  background: none !important;
}

/* 人物高亮 */
.peoperColor {
  transform: scale(1.05, 1.05) !important;

  box-shadow: rgb(255 250 250 / 20%) 0 1px 13px !important;
  border-radius: 10px !important;
}

.username {
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: center;
}

.username i {
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background-color: #1af11a;
  margin-right: 10px;
}


</style>
