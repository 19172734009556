<!-- 人员管理 -->
<template>
  <!-- 浮窗 -->
  <div class="mask"  v-if="showWrap">
    <!-- 任务职责 -->
    <div class="peoperLeft">
      <span>总经理</span>
      <img @click="" src="../../../../assets/img/apply/总经理单.png" alt="" />
    </div>
    <div class="wrap" >
      <div>
        <span>角色分配</span>
      </div>
      <img
        src="../../../../assets/img/apply/错号.png"
        @click="router.go(-1)"
      />
      <!-- <select @change="peopmessage" v-model="messageValue">
        <option value="人员调整">人员调整</option>
        <option value="工资调整">工资调整</option>
      </select> -->
      <!-- <el-table :data="data22.userData" style="width:500PX" >
        <el-table-column prop="name" label="角色" width="250"  ></el-table-column>
        <el-table-column prop="id" label="账号" width="250">
          <template #default="scope">
            <el-select v-model="scope.row.id" placeholder="请选择">
              <el-option v-for="item in data22.users" :label="item.name" :value="item.id"></el-option>
            </el-select>
          </template>
        </el-table-column>
      </el-table> -->

      <div class="text" id="table">

        <div class="table">
          <table>
          <thead>
            <tr>
              <th>角色</th>
              <th>账号</th>
              
            </tr>
          </thead>
          <tbody v-if="data22.userData.length > 0">
            <tr v-for="item in data22.userData" :key="item.uid">
              <td>{{ item.name }}</td>
              <td>
                <el-select v-model="item.id" placeholder="请选择">
              <el-option v-for="it in data22.users" :key="it.id" :label="it.name" :value="it.id"></el-option>
            </el-select>
              </td>
              
              
            </tr>
          </tbody>
        </table>
        </div>
        
      </div>
      
      <div class="cbtns">
        <span @click="fun()">确定</span>
      </div>
    </div>
  </div>
</template>

<script setup>

import { reactive, ref,  toRefs } from "vue";
import { useRouter } from "vue-router";
import { useAxios } from "@/api";
const https = useAxios();
import { ElMessage } from 'element-plus'
import {homeData } from '@/Pinia/Home'


const homeStore = homeData();
const router = useRouter();
const messageValue = ref("人员调整");
const peopmessage = () => {
  if (messageValue.value == "工资调整") {
    router.replace("/personnel/gmanager/gm7");
  }
};





//里面定义一个对象，这样赋值就不会丢失响应式了。

let data22 = reactive({
    userData:[],
    user:[]
})

let data23 = reactive([]);
// 发送请求
// 人员管理-人员调整-获取小组成员列表
const homeLink = () => {
  // 小组id
  let homelinkId = sessionStorage.getItem("userid");

  https.post("/role1/rygl/user", { tId: homelinkId }).then((res) => {
     
        data22.userData = res.data.data.roles;
        data23.push(res.data.data.roles);
        data22.users = res.data.data.users;
  });
};
homeLink();



const showWrap = ref(true);
const homeLink2 = () => {
  // 小组id
  let homeIdlink= sessionStorage.getItem("userid");

  https.post("/role1/rygl/save", { tId: homeIdlink,value:JSON.stringify(data22.userData)}).then(({data}) => {



    if(data.success) {
      ElMessage({
          message: "提交成功.",
          type: "success",
        });
        router.push({path:'/'});
        showWrap.value = false;

        homeStore.setRefreshHome(true)
    }else {
      ElMessage.error(data.message);
    }
    

  });
};
homeLink();
function fun (){
  homeLink2();
}
</script>

<style scoped lang="scss">

/* @import url("@/assets/publicCSS/tableCSS.css"); */
* {
  box-sizing: border-box;
}

.mask {
  width: 1920px;
  height: 1080px;
  background: rgba(0, 0, 0, 0.6);
  position: absolute;
  top: 0;
}

.wrap {
  width: 914px;
  height: 654px;
  background-image: url(../../../../assets/img/apply/弹窗.png);
  background-repeat: no-repeat;
  background-size: 100% 100%;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  position: relative;
}

.text {
  width: 100%;

  padding-top: 210px;
  padding: 210px 40px 0 75px;
  

  .table {
    overflow-y: auto;
    max-height: 350px;
  }
}
.el-table  {
      top: 215px;
      left: 246px;   
}


:deep(.el-table th.el-table__cell) {
  
    background-color:#0c7eb0;
    color: white;
    /* z-index: 99999; */
}

.wrap > img {
  width: 59px;
  height: 40px;
  position: absolute;
  top: 120px;
  right: 5px;
  cursor: pointer;
}

.wrap > div:nth-of-type(1) {
  position: absolute;
  top: 140px;
  left: 30px;
  width: 96%;
  margin: auto;
  text-align: center;
}

.wrap > div:nth-of-type(1) > span {
  width: 144px;
  height: 25px;
  font-size: 24px;
  font-family: Microsoft YaHei;
  font-weight: bold;
  color: #00a8ff;
}

option {
  background-color: #1a466d;
}

select {
  width: 120px;
  background-color: transparent;
  color: #ffffff;
}

.wrap > select {
  position: absolute;
  top: 190px;
  left: 246px;
  border: 1px solid #ffffff;
}

td > select {
  width: 200px;
  border: 0;
}
.tb-select {
  color: #0c7eb0;
}



.cbtns {
  position: absolute;
  left:53%;
  transform: translate(-50%,0);
  top: 560px;
}

.cbtns > span {
  display: inline-block;
  width: 115px;
  height: 54px;
  line-height: 54px;
  background-image: url(../../../../assets/img/apply/新建1.png);
  background-repeat: no-repeat;
  background-size: 100%;
  /* margin-right: 22px; */
  font-size: 14px;
  -webkit-transform-origin-x: 0;
  /*缩小后文字居左*/
  -webkit-transform: scale(0.8);
  /*关键*/
  transform: scale(0.8);
  cursor: pointer;
  color: #ffffff;
  text-align: center;
}

.cbtns > span:hover {
  opacity: 0.8;
}

.upmake {
  width: 906px;
  height: 280px;
  background-color: gainsboro transparent;
  position: absolute;
  top: 109px;
}
.block-col-2 .demonstration {
  display: block;
  color: var(--el-text-color-secondary);
  font-size: 14px;
  margin-bottom: 20px;
}

/* 角色分配单独写 */

#table {
    /* 表格的样式 */
    table {
    border-collapse: separate;
    table-layout: fixed;
    width: 100%;
    text-align: center;
  
  }
  

  thead tr th {
    text-align: center;
    position: sticky;
    top: 0;

    z-index: 100;
  }
  
  th {
    background: linear-gradient(#00366e, #0052a6);
    color: #fefefe;
    font-size: 18px;
    height: 60px;
    font-weight: 600;
  }
  
  thead > tr > th,
  tbody > tr > td {
    box-shadow: #d9d9d9 2px 0 5px;
    text-align: center;
  }
  
  tr > td {
    background: linear-gradient(#d9d9d9, #f7f7f7);
    color: #333333;
    font-size: 15px;
    height: 44px;
    padding: 4px 10px;
  }
  
  tr > td:nth-of-type(1) {
    width: 288px;
    height: 44px;
    background: linear-gradient(#0c72b3, #2a87c9);
    color: #fefefe;
    font-size: 15px;
  }

  tbody > tr > td:first-child input {
    color: #fff;
  }
}
</style>