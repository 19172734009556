import axios from "axios";
import route from '@/router/index.js'

// const router = useRouter()
import { showLoading, hideLoading } from "@/utils/loading";


const requests = axios.create({
    // https://api.evsp.ibeisai.com/v5     线上
    // http://192.168.31.128:7090/rselectool/v5 ,开发
    baseURL:'https://api.evsp.ibeisai.com/v5',
    // baseURL:'/api',
    
    headers: {
        "content-Type": "application/json"
    }
})

// 请求拦截
requests.interceptors.request.use(
    config => {
        showLoading()
        if (sessionStorage.getItem('token')) {
            config.headers['token'] = sessionStorage.getItem('token');
        }
        
        return config;
    },
    error => {

        return Promise.error(error);
    })

// 响应拦截
requests.interceptors.response.use(
    res => {
        
        if (res.status === 200) {
            hideLoading()

            if (res.data.code == -4) {
                route.push({
                    path:'/login'
                })
            }
            return Promise.resolve(res)
        }

        
    },
    error => {
      
        // hideLoading()
        // if (error.response.status) {
        //     console.log(error.response.status)
        // }
    }
);



export const useAxios = () => {
    const get = (url, data) => {
        return requests.get(url, { params: { data } })
    }
    const post = (url, data) => {
        return requests.post(url, data)
    }
    const upload = (url, data) => {
        return axios.post(url, data)
    }
    const del = (url, data) => {
        return requests.delete(url, data)
    }
    return { get, post, upload, del }
}